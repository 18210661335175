import React from "react";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import WebAssetOff from "./assets/images/web_asset_off.svg";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  title: {
    margin: "2vw 0",
    fontWeight: "bolder",
  },
  boxCenter: {
    textAlign: "center",
  },
  boxGroup: {
    margin: "1vh 7vw",
  },
  btn: {
    color: "#261A00",
    border: "#261A00 1px solid",
    boxShadow: "none",
    backgroundColor: "#fff",
    "&:hover": { backgroundColor: "#fff" },
  },
  img: {
    margin: "3vh",
  },
});

export default function NotFoundPage() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <Box className={classes.title}></Box>
      </Grid>
      <Container maxWidth="sm">
        <Box className={classes.boxCenter}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Box className="screenLock">
                <Box className={classes.img}>
                  <img src={WebAssetOff} alt="WebAssetOff"></img>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bolder",
                }}
              >
                很抱歉
              </Typography>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bolder",
                }}
              >
                我們找不到您要找的頁面
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="button"
                style={{
                  fontWeight: "bolder",
                }}
              >
                您可以透過聯繫我們的客服部門來尋求幫助
                <br />
                客服信箱：service_tw@whirlpool.com
                <br />
                客服專線：0800-258-558（市話）;
                <br /> 02-66211686（手機直撥）
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box className={classes.boxGroup}>
                <Button
                  className={classes.btn}
                  href="/"
                  variant="outlined"
                  fullWidth
                >
                  重新查詢
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
}
