import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import LocalShipping from "../assets/images/local_shipping.svg";
import Construction from "../assets/images/construction.svg";
import CalenderToday from "../assets/images/calendar_today.svg";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import Footer from "../Footer";

const useStyles = makeStyles({
  iconCenter: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  iconRight: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  iconLeft: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  iconProps: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFDF9D",
    borderRadius: 8,
    width: "72px",
    height: "72px",
  },
  CenterPosition: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  boxCenter: {
    textAlign: "center",
  },
  boxGroup: {
    marginBottom: "16px",
  },
});

const useStylesInRWD = makeStyles((theme) => ({
  button: {
    backgroundColor: "#FABD06",
    "&:hover": { backgroundColor: "#ffc542" },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "182px",
    },
  },
}));

export default function Check() {
  const classes = useStyles();
  const classesInRWD = useStylesInRWD();
  const [serialNum, setSerialNum] = useState("");
  const [serialNumError, setSerialNumError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSerialNumError(false);

    if (serialNum === "") {
      setSerialNumError(true);
    }

    if (serialNum) {
      navigate("/order/" + serialNum);
    }
  };

  return (
    <div>
      <Container maxWidth="md">
        <Box className={classes.boxCenter}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Box className={classes.boxGroup}>
                <TextField
                  onChange={(e) => setSerialNum(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="medium" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="W 訂單編號、W 配送編號、PO編號"
                  fullWidth
                  required
                  style={{
                    backgroundColor: "#F5EDE4",
                    borderRadius: "4px",
                  }}
                  error={serialNumError}
                />
              </Box>
              <Box className={classes.boxGroup}>
                <Button
                  className={classesInRWD.button}
                  type="submit"
                  variant="contained"
                  style={{
                    fontWeight: 400,
                    height: "48px",
                    boxShadow: "none",
                  }}
                >
                  查詢
                </Button>
              </Box>
            </form>
          </Grid>
          <Grid
            style={{
              marginTop: "72px",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              訂單查詢系統
            </Typography>
            <Typography
              variant="caption"
              style={{
                margin: "1vh",
                fontWeight: 400,
                fontSize: "16px",
              }}
            >
              提供您更全面的訂單狀態
            </Typography>
          </Grid>
          <Box marginTop={2}>
            <Grid container spacing={2}>
              <Grid item xs={4} sm={5} md={5} className={classes.iconRight}>
                <Box width="100px">
                  <Box className="screenLock">
                    <Box className={classes.CenterPosition}>
                      <Box className={classes.iconProps}>
                        <img src={LocalShipping}></img>
                      </Box>
                    </Box>
                  </Box>
                  <Typography style={{ margin: "1vh auto" }}>
                    配送進度
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={2} md={2} className={classes.iconCenter}>
                <Box width="100px">
                  <Box className="screenLock">
                    <Box className={classes.CenterPosition}>
                      <Box className={classes.iconProps}>
                        <img src={Construction}></img>
                      </Box>
                    </Box>
                  </Box>
                  <Typography style={{ margin: "1vh auto" }}>
                    安裝進度
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} sm={5} md={5} className={classes.iconLeft}>
                <Box width="100px">
                  <Box className="screenLock">
                    <Box className={classes.CenterPosition}>
                      <Box className={classes.iconProps}>
                        <img src={CalenderToday}></img>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Typography style={{ margin: "1vh auto" }}>
                      預期到貨日期
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <div style={{ marginBottom: "20vh" }}></div>
      <Footer />
    </div>
  );
}
