import React from "react";
import Box from "@material-ui/core/Box";
import LensIcon from "@material-ui/icons/Lens";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  progress: {
    textAlign: "center",
    margin: "2vh 0",
  },
  progressItems: {
    maxWidth: "20%",
    flexGrow: 0,
    flexBasis: "100%",
    zIndex: 1,
  },
  progressText: {
    fontSize: "14px",
  },
  line: {
    width: "20%",
    height: "3px",
    display: "block",
    position: "absolute",
  },
  linePosition1: {
    position: "relative",
    top: "10px",
    left: "10%",
  },
  linePosition2: {
    position: "relative",
    top: "10px",
    left: "30%",
  },
  linePosition3: {
    position: "relative",
    top: "10px",
    left: "50%",
  },
  linePosition4: {
    position: "relative",
    top: "10px",
    left: "70%",
  },
});

export default function Progress(props) {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.linePosition1}>
        <Box
          component="span"
          className={classes.line}
          style={{
            backgroundColor: props.status >= 1 ? "#FABD06" : "#D0C5B4",
          }}
        ></Box>
      </Box>
      <Box className={classes.linePosition2}>
        <Box
          component="span"
          className={classes.line}
          style={{
            backgroundColor: props.status >= 2 ? "#FABD06" : "#D0C5B4",
          }}
        ></Box>
      </Box>
      <Box className={classes.linePosition3}>
        <Box
          component="span"
          className={classes.line}
          style={{
            backgroundColor: props.status >= 3 ? "#FABD06" : "#D0C5B4",
          }}
        ></Box>
      </Box>
      <Box className={classes.linePosition4}>
        <Box
          component="span"
          className={classes.line}
          style={{
            backgroundColor: props.status >= 4 ? "#FABD06" : "#D0C5B4",
          }}
        ></Box>
      </Box>
      <Grid container className={classes.progress}>
        <Grid item className={classes.progressItems}>
          <Box>
            <LensIcon
              fontSize="medium"
              htmlColor={props.status >= 0 ? "#FABD06" : "#D0C5B4"}
            />
            <Typography className={classes.progressText}>
              {props.arrayProgess[0]}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.progressItems}>
          <Box>
            <LensIcon
              className={classes.circleIcon}
              fontSize="medium"
              htmlColor={props.status >= 1 ? "#FABD06" : "#D0C5B4"}
            />
            <Typography className={classes.progressText}>
              {props.arrayProgess[1]}
            </Typography>
          </Box>
        </Grid>
        <Box component="span"></Box>
        <Grid item className={classes.progressItems}>
          <Box>
            <LensIcon
              fontSize="medium"
              htmlColor={props.status >= 2 ? "#FABD06" : "#D0C5B4"}
            />
            <Typography className={classes.progressText}>
              {props.arrayProgess[2]}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.progressItems}>
          <Box>
            <LensIcon
              fontSize="medium"
              htmlColor={props.status >= 3 ? "#FABD06" : "#D0C5B4"}
            />
            <Typography className={classes.progressText}>
              {props.arrayProgess[3]}
            </Typography>
          </Box>
        </Grid>
        <Grid item className={classes.progressItems}>
          <Box>
            <LensIcon
              fontSize="medium"
              htmlColor={props.status >= 4 ? "#FABD06" : "#D0C5B4"}
            />
            <Typography className={classes.progressText}>
              {props.arrayProgess[4]}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
