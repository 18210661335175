//正式站
const apiDomain = "https://tracking.whirlpool.com.tw";
//測試站
const apiDomainUAT = "https://whirlpool.perfect.tw";
//本地
const apiDomainLocal = "http://localhost:3030";

const apiEndpoints = {
  posts: `${apiDomain}/backend/API/GetOrder.ashx`,
  UAT: `${apiDomainUAT}/Backend/Api/GetOrder.ashx`,
  testJSON: `${apiDomainUAT}`,
  local: `${apiDomainLocal}/posts`,
};

export default apiEndpoints;
