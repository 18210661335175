import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Progress from "./Progress";
import apiEndpoints from "../apiConfig";

const useStyles = makeStyles({
  title: {
    margin: "2vw 0",
    fontWeight: "bolder",
  },
  items: {
    color: "#5B4300",
    fontWeight: "400",
    fontSize: "14px",
  },
  itemsData: {
    color: "#261A00",
    fontWeight: "400",
    fontSize: "14px",
  },
  itemLineHeight: {
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
  },
  progressBox: {
    minHeight: "5vh",
    margin: "0 0 8px 0",
    backgroundColor: "#FAF2E9",
    padding: "15px 16px",
    borderRadius: "4px",
  },
  details: {
    padding: "0 16px 16px 16px",
  },
  progress: {
    minHeight: "7vh",
  },
  boxGroup: {
    margin: "1vh",
  },
  btn: {
    width: "100%",
    height: "48px",
    color: "#261A00",
    border: "#261A00 1px solid",
    boxShadow: "none",
    backgroundColor: "#fff",
    "&:hover": { backgroundColor: "#fff" },
  },
});
export default function Order() {
  const [loading, setLoading] = useState(true);
  const [detail, setDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({ title: "", content: "" });
  const navigate = useNavigate();
  const classes = useStyles();
  const params = useParams();
  const [deliveryProgress, setDeliveryProgress] = useState([]);
  const [installProgress, setInstallProgress] = useState([]);
  console.log(params);
  const getData = async () => {
    try {
      const response = await fetch(
        apiEndpoints.posts + "?OrderNumber=" + params.serialNum
        //apiEndpoints.UAT + "?OrderNumber=" + params.serialNum
        //apiEndpoints.testJSON + "/db.json",
      );
      if (response.status === 200) {
        return await response.json();
      } else {
        throw { message: "Error message status: " + response.status };
      }
    } catch (error) {
      console.log("error", error.message);
      setDialog({ title: "Error", content: error.message });
      setOpen(true);
    }
  };

  useEffect(() => {
    // 在這裡可以進行一些耗時的操作，例如發送 API 請求
    const fetchData = async () => {
      const jsonData = await Promise.resolve(getData());
      console.log(jsonData);
      const data = jsonData.data;
      //const data = jsonData;
      if (data != null) {
        let aryDelivery = ["成立訂單", "準備配送", "檢貨", "配送", "客戶簽收"];
        let aryInstall = ["已派工", "約配完成", "配送中", "配達", "結案"];
        // 測試資料
        // let hasData = false;
        // data.posts.forEach((element) => {
        //   if (element.orderId === params.serialNum) {
        //     element.updated = format(
        //       new Date(element.updated),
        //       "yyyy/MM/dd HH:mm"
        //     );
        //     setDetails(element);
        //     hasData = true;
        //   }
        // });
        // if (!hasData) {
        //   navigate("/order");
        // }
        // setLoading(false);
        // 完成後將 setLoading 設為 false
        data.updated = format(new Date(data.updated), "yyyy/MM/dd HH:mm");
        if (
          data.installStatus > -1 ||
          (data.installFlag === "Y" && data.installStatus === -1)
        ) {
          aryDelivery[4] = "安裝人員處理中";
        }
        setDeliveryProgress(aryDelivery);
        setInstallProgress(aryInstall);
        setDetails(data);
        setLoading(false);
      } else {
        navigate("/order");
      }
    };

    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <Container maxWidth="lg">
      {loading && (
        <Backdrop open={loading} style={{ zIndex: "999" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid item xs={12}>
        <Box className={classes.title}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bolder",
            }}
          >
            訂單詳情
          </Typography>
        </Box>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            marginBottom: "25px",
          }}
        >
          <Grid container className={classes.progressBox}>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>訂單狀態</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.orderStatus}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>
                預計到貨日/安裝日
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.expectedDate}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.arrivalDate}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.itemLineHeight}
            >
              <Typography
                className={classes.items}
                style={{ color: "#2327e5" }}
              >
                實際到貨日期請依各物流作業為主。
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.progressBox} item xs={12}>
            <Typography className={classes.items}>配送進度</Typography>
            <Box className={classes.progress}>
              <Progress
                status={detail.deliveryStatus}
                arrayProgess={deliveryProgress}
              />
            </Box>
          </Grid>
          <Grid
            className={classes.progressBox}
            item
            xs={12}
            style={{ display: detail.installStatus === -1 ? "none" : "" }}
          >
            <Typography className={classes.items}>安裝進度</Typography>
            <Box className={classes.progress}>
              <Progress
                status={detail.installStatus}
                arrayProgess={installProgress}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Grid container className={classes.details}>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>W 訂單編號</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.orderId === null ? "N/A" : detail.orderId}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>W 配送編號</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.deliveryId === null ? "N/A" : detail.deliveryId}
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>PO編號</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.websiteId === null ? "N/A" : detail.websiteId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <hr></hr>
            </Grid>
            <Grid item xs={4} sm={4} md={4} className={classes.itemLineHeight}>
              <Typography className={classes.items}>更新時間</Typography>
            </Grid>
            <Grid item xs={8} sm={8} md={8} className={classes.itemLineHeight}>
              <Typography className={classes.itemsData}>
                {detail.updated}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3} md={2}>
        <Box className={classes.boxGroup}>
          <Button className={classes.btn} href="/" variant="contained">
            重新查詢
          </Button>
        </Box>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>{dialog.content}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
