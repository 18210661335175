import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import NavBar from "./NavBar";

import reportWebVitals from "./reportWebVitals";

// React.StrictMode 會導致 useEffect 執行兩次 (參考資料:https://flaviocopes.com/react-useeffect-two-times/)
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <NavBar />
//     <main>
//       <App />
//     </main>
//     <Footer />
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div style={{ overflowX: "hidden" }}>
    <NavBar />
    <App />
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
