import logo from "./LOGO.svg";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

function NavBar() {
  return (
    <AppBar
      position="static"
      style={{
        background: "black",
        boxShadow: "none",
        marginBottom: "24px",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box
            className="screenLock"
            sx={{
              flexGrow: 1,
              display: { xs: "grid", sm: "flex", md: "flex" },
            }}
          >
            <Button color="inherit">
              <img src={logo} />
              <Typography
                variant="caption"
                noWrap
                style={{
                  fontWeight: 400,
                  fontSize: "16px",
                  margin: "8px",
                  font: "PingFang TC",
                }}
              >
                訂單查詢系統
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;
