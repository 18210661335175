import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Check from "./pages/Check";
import Order from "./pages/Order";
import OrderNotFound from "./pages/OrderNotFound";
import NotFoundPage from "./NotFoundPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Check />} />
        <Route exact path="/index.html" element={<Check />} />
        <Route exact path="/order" element={<OrderNotFound />} />
        <Route exact path="/order/:serialNum" element={<Order />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
