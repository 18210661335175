import React from "react";
import { makeStyles } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import SearchOff from "../assets/images/search_off.svg";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  title: {
    margin: "2vw 0",
    fontWeight: "bolder",
  },
  boxCenter: {
    textAlign: "center",
  },
  boxGroup: {
    margin: "1vh 7vw",
  },
  btn: {
    height: "48px",
    width: "182px",
    color: "#261A00",
    border: "#261A00 1px solid",
    boxShadow: "none",
    backgroundColor: "#fff",
    "&:hover": { backgroundColor: "#fff" },
  },
  img: {
    margin: "3vh",
  },
});

export default function OrderNotFound() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <Box className={classes.title}>
          <Typography
            variant="h6"
            style={{
              fontWeight: "bolder",
            }}
          >
            訂單詳情
          </Typography>
        </Box>
      </Grid>
      <Container maxWidth="sm">
        <Box className={classes.boxCenter}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={12} md={12}>
              <Box className="screenLock">
                <Box className={classes.img}>
                  <img src={SearchOff} alt="SearchOff"></img>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                style={{
                  fontWeight: "bolder",
                }}
              >
                找不到相關訂單
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="button"
                style={{
                  fontWeight: 400,
                }}
              >
                您可以確認編號後再次查詢或稍後再試
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Box className={classes.boxGroup}>
                <Button
                  className={classes.btn}
                  href="/"
                  variant="outlined"
                  fullWidth
                >
                  重新查詢
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
}
