import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        position: "fixed",
        bottom: 0,
        background: "black",
        color: "white",
      }}
    >
      <Container maxWidth="md">
        <Box style={{ textAlign: "center" }}>
          <Typography
            variant="body2"
            style={{
              margin: "1vh auto",
              fontWeight: 400,
              fontSize: "12px",
            }}
          >
            ® Registered trademark/TM Trademark of Whirlpool Properties, LLC or
            its related companies.
            <br />© 2023 Whirlpool All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </footer>
  );
}
export default Footer;
